import React, { useMemo } from 'react'
import { formatMoney } from '../../util'
import { ShoppingCartItemProduct as ShoppingCartItemProductType } from '../../types/application-types'

type Props = {
  product: ShoppingCartItemProductType
}

const ShoppingCartItemProduct: React.FC<Props> = ({ product }) => {
	/** State Management */
	const productPrice = useMemo(() => formatMoney(product.price), [product])

	return (
		<li className="text-base font-medium text-gray-800 py-4 flex flex-col">
			<div className="flex justify-between mb-0.5">
				<div className="flex gap-2">
					<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{product.amount}</span>
					<span>{product.name}</span>
				</div>
				<span>{productPrice}</span>
			</div>
		</li>
	)
}

export default React.memo<Props>(ShoppingCartItemProduct)
