import React, { useMemo } from 'react'
import { Oval } from 'react-loader-spinner'
import TicketSelect from './ticket-select'
import { useApplication } from '../../../providers/application-provider'
import { TicketState, Ticket as TicketType } from '../../../types/application-types'
import { classNames, formatMoney } from '../../../util'

type Props = {
  maxAmount: number;
  disabled: boolean;
  ticket: TicketType;
  onSelectTickets: (ticketId: string, amount: number) => void
};

const Ticket: React.FC<Props> = ({ ticket, disabled, maxAmount, onSelectTickets }) => {
	const {
		application: { activeEvent }
	} = useApplication()

	/** State Management */
	const ticketPrice = useMemo(() => formatMoney(ticket.price), [ticket.price])

	const persisting = useMemo(
		() =>
			ticket.state === TicketState.RESERVING ||
      ticket.state === TicketState.UPDATING,
		[ticket.state]
	)
	const hasError = useMemo(
		() =>
			ticket.state === TicketState.RESERVING_FAILED ||
      ticket.state === TicketState.UPDATING_FAILED,
		[ticket.state]
	)

	/** Methods */
	const onSelectAmount = (amount: number) => onSelectTickets(ticket.id, amount)

	return (
		<div
			className={classNames(
				hasError ? 'border-red-500' : '',
				'flex-column border-2 rounded-md py-4 px-6'
			)}
		>
			<div className="flex justify-between gap-4">
				<div className="flex flex-col gap-1">
					<h4 className="flex text-xl font-medium">{ticket.name}</h4>
					{ticket.description && (
						<h5 className="text-md text-gray-500">{ticket.description}</h5>
					)}
					<div className="flex mt-1">
						<span className="text-lg font-medium">{ticketPrice}</span>
					</div>
				</div>
				{persisting ? (
					<div className="flex justify-center self-center min-w-24">
						<Oval
							visible
							height="30"
							width="30"
							color={activeEvent?.accentColor ?? '#000000'}
							secondaryColor={`${activeEvent?.accentColor ?? '#000000'}80`}
							ariaLabel="persisting-ticket"
						/>
					</div>
				) : (
					<TicketSelect
						disabled={disabled}
						ticketId={ticket.id}
						maxAmount={maxAmount}
						minAmount={ticket.minAmountPerOrder}
						availableAmount={ticket.v}
						onSelectAmount={onSelectAmount}
					/>
				)}
			</div>
			{hasError && (
				<div className="text-red-600 pt-2 font-medium">
          Er is iets fout gelopen bij het reserveren van uw tickets. Gelieve
          opnieuw te proberen.
				</div>
			)}
		</div>
	)
}

export default React.memo<Props>(Ticket)
