import React, { useMemo } from 'react'

type Props = {
  description: string
}

type TextNode = {
	text: string
	bold?: boolean
	italic?: boolean
}

type LinkNode = {
	type: 'link'
	url: string
	children: Node[]
}

type ItemNode = {
	type: 'list-item'
	children: Node[]
}

type ListNode = {
	type: 'ordered-list' | 'unordered-list'
	children: ItemNode[]
}

type ParagraphNode = {
	type: 'paragraph'
	children: Node[]
}

type SubheadingNode = {
	type: 'subheading-1' | 'subheading-2'
	children: Node[]
}

type HorizontalRuleNode = {
	type: 'horizontal-rule'
	children: Node[]
}

type Node = 
	| TextNode
	| LinkNode
	| ListNode
	| ParagraphNode
	| SubheadingNode
	| HorizontalRuleNode

const strong = (innerHTML: string) => {
	return `<strong>${innerHTML}</strong>`
}

const i = (innerHTML: string) => {
	return `<i>${innerHTML}</i>`
}

const renderTextNode = (node: TextNode): string => {
	let text = node.text
	if (node.italic) {
		text = i(text)
	}
	if (node.bold) {
		text = strong(text)
	}
	return text
}

const isTextNode = (node: any): node is TextNode => node.text != null


const escapeHtml = (unsafe: string) => {
	if (!unsafe) {
		return ''
	}
	return unsafe
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#039;')
}

const a = (innerHTML: string, url: string) => `<a rel="nofollow" href="${escapeHtml(url)}" target="_blank">${innerHTML}</a>`

const li = (innerHTML: string) => `<li>${innerHTML}</li>`

const ul = (innerHTML: string) => `<ul style="list-style-type: disc; list-style-position: inside;">${innerHTML}</ul>`

const ol = (innerHTML: string) => `<ol style="list-style-type: decimal; list-style-position: inside;">${innerHTML}</ol>`

const p = (innerHTML: string) => `<p>${innerHTML}</p>`

const h2 = (innerHTML: string) => `<div style="font-size: 1.5rem; line-height: 2rem; font-weight: 700;">${innerHTML}</div>`

const h3 = (innerHTML: string) => `<div style="font-size: 1.125rem; line-height: 1.75rem; font-weight: 700;">${innerHTML}</div>`

const renderNode = (node: Node | ItemNode): string => {
	if (isTextNode(node)) {
		return renderTextNode(node)
	}

	const innerHTML = toHTML(node.children)
	switch (node.type) {
	case 'link':
		return a(innerHTML, node.url)
	case 'list-item':
		return li(innerHTML)
	case 'ordered-list':
		return ol(innerHTML)
	case 'paragraph':
		return p(innerHTML)
	case 'unordered-list':
		return ul(innerHTML)
	case 'subheading-1':
		return h2(innerHTML)
	case 'subheading-2':
		return h3(innerHTML)
	case 'horizontal-rule':
		return '<hr>'
	default:
		return innerHTML
	}
}

const toHTML = (nodes: Node[] | ItemNode[]) => {
	return nodes.map(renderNode).join('')
}

const Description: React.FC<Props> = ({ description }) => {
	/** State Management */
	const nodes: Node[] = useMemo(() => {
		const trimmedDescription = description.trim()
		if (trimmedDescription.length === 0) {
			return []
		}
		return JSON.parse(description)
	}, [description])

	if (nodes.length === 0) return null

	const markup = { __html: toHTML(nodes) }

	return <div className="text-lg text-gray-800 flex flex-col gap-3 mt-6"  dangerouslySetInnerHTML={markup} />
}

export default React.memo<Props>(Description)
