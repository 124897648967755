import groupBy from 'lodash/groupBy'
import React, { useMemo } from 'react'
import Main from '../../components/layout/main'
import Banner from '../../components/banner'
import Footer from '../../components/layout/footer'
import Header from '../../components/layout/header'
import Content from '../../components/layout/content'
import ContentLeft from '../../components/layout/content-left'
import ContentRight from '../../components/layout/content-right'
import TicketCategory from './components/ticket-category'
import ShoppingCart from '../../components/shopping-cart'
import { useApplication } from '../../providers/application-provider'
import {
	Ticket,
	TicketCategory as TicketCategoryType,
	TicketState
} from '../../types/application-types'

const Tickets: React.FC = () => {
	const {
		application: { activeEvent, reserveTickets }
	} = useApplication()

	/** Methods */
	const onSelectTickets = async (ticketId: string, amount: number) =>
		reserveTickets(ticketId, amount)

	/** State Management */
	const persisting = useMemo(() => {
		if (!activeEvent) return false
		return activeEvent?.tickets.some(
			({ state }) =>
				state === TicketState.RESERVING || state === TicketState.UPDATING
		)
	}, [activeEvent?.tickets])
	
	const ticketsPerCategory: Record<string, Ticket[]> = useMemo(() => {
		if (!activeEvent) return {}
		return groupBy(
			activeEvent.tickets.filter(({ active }) => active),
			'categoryRef'
		)
	}, [activeEvent?.tickets])

	const renderTicketCategories = () => {
		if (!activeEvent) return []

		const totalAmountOfTicketsForEvent = Object.values(ticketsPerCategory).reduce((acc, tickets) => acc + tickets.reduce((acc, { amount }) => acc + amount, 0), 0)
		
		return activeEvent.categories.reduce(
			(acc: React.ReactNode[], category: TicketCategoryType, idx: number) => {
				const ticketsForCategory = ticketsPerCategory[category.ref] || []
				if (ticketsForCategory.length === 0) return acc

				const maxAmountEvent = activeEvent.max - totalAmountOfTicketsForEvent

				const totalAmountOfTicketsForCategory = ticketsForCategory.reduce((acc, { amount }) => acc + amount, 0)
				const maxAmountCategory = category.maxAmountPerOrder < category.v 
					? category.maxAmountPerOrder - totalAmountOfTicketsForCategory 
					: category.v - totalAmountOfTicketsForCategory
					
				return [
					...acc,
					<TicketCategory
						key={`ticketCategory${idx}`}
						category={category}
						disabled={persisting}
						tickets={ticketsForCategory}
						maxAmount={maxAmountEvent < maxAmountCategory ? maxAmountEvent : maxAmountCategory}
						onSelectTickets={onSelectTickets}
					/>
				]
			},
			[]
		)
	}

	return (
		<div className="flex flex-col grow shrink-0 basis-auto">
			<Header />
			<Main>
				<Banner />
				<Content>
					<ContentLeft labelledby="tickets-select">
						<h2 id="tickets-title" className="text-2xl font-bold mb-6">Selecteer jouw tickets</h2>
						<div className="flex flex-col gap-8">{renderTicketCategories()}</div>
					</ContentLeft>
					<ContentRight>
						<ShoppingCart persisting={persisting} />
						{/* <PromotionCode /> */}
					</ContentRight>
				</Content>
			</Main>
			<Footer />
		</div>
	)
}

export default React.memo(Tickets)
