import { ApiCheckout } from '../../types/api-types'
import { Event, FixedPriceProduct, ProductState, RangePriceProduct, ShoppingCart, TicketState } from '../../types/application-types'

enum ActionTypes {
  RESERVE_TICKETS = 'RESERVE_TICKETS',
	RESERVE_PRODUCTS = 'RESERVE_PRODUCTS',
	SET_CREATING_CHECKOUT = 'SET_CREATING_CHECKOUT',
	SET_CHECKOUT = 'SET_CHECKOUT',
	SET_CHECKOUT_EXPIRED = 'SET_CHECKOUT_EXPIRED',
  SET_REHYDRATING = 'SET_REHYDRATING',
  SET_REHYDRATING_CHECKOUT = 'SET_REHYDRATING_CHECKOUT',
  SET_ACTIVE_EVENT = 'SET_ACTIVE_EVENT',
  SET_SHOPPING_CART = 'SET_SHOPPING_CART',
  CHANGE_TICKET_STATE = 'CHANGE_TICKET_STATE',
  CLEAR_TICKETS = 'CLEAR_TICKETS',
	SET_PRODUCTS = 'SET_PRODUCTS',
	CHANGE_PRODUCT_STATE = 'CHANGE_PRODUCT_STATE'
}

type ReserveTicketsActionType = {
  type: ActionTypes.RESERVE_TICKETS
  payload: {
    ticketId: string
    amount: number
  }
}

type ReserveProductsActionType = {
	type: ActionTypes.RESERVE_PRODUCTS
	payload: {
		productId: string
		amount: number
		price?: number
	}
}

type SetCreatingCheckoutActionType = {
	type: ActionTypes.SET_CREATING_CHECKOUT
	payload: {
		value: boolean
	}
}

type SetCheckoutActionType = {
	type: ActionTypes.SET_CHECKOUT
	payload: {
		checkout: ApiCheckout | null
	}
}

type SetCheckoutExpiredActionType = {
	type: ActionTypes.SET_CHECKOUT_EXPIRED
	payload: {
		checkoutExpired: boolean
	}
}

type SetRehydratingActionType = {
  type: ActionTypes.SET_REHYDRATING
  payload: {
    value: boolean
  }
}

type SetRehydratingCheckoutActionType = {
  type: ActionTypes.SET_REHYDRATING_CHECKOUT
  payload: {
    value: boolean
  }
}

type SetActiveEventActionType = {
  type: ActionTypes.SET_ACTIVE_EVENT
  payload: {
    event: Event | null
  }
}

type SetShoppingCartActionType = {
  type: ActionTypes.SET_SHOPPING_CART
  payload: {
    shoppingCart: ShoppingCart | null
  }
}

type ChangeTicketActionType = {
  type: ActionTypes.CHANGE_TICKET_STATE
  payload: {
    ticketId: string
    state: TicketState
		amountLeft?: number
  }
}

type ChangeProductStateActionType = {
	type: ActionTypes.CHANGE_PRODUCT_STATE
	payload: {
		productId: string
		state: ProductState
	}
}

type ClearTicketsActionType = {
  type: ActionTypes.CLEAR_TICKETS
}

type SetProductsActionType = {
	type: ActionTypes.SET_PRODUCTS
	payload: {
		products: (FixedPriceProduct | RangePriceProduct)[]
	}
}

const reserveTicketsAction = (ticketId: string, amount: number): ReserveTicketsActionType => ({
	type: ActionTypes.RESERVE_TICKETS,
	payload: {
		ticketId,
		amount
	}
})

const reserveProductsAction = (productId: string, amount: number, price?: number): ReserveProductsActionType => ({
	type: ActionTypes.RESERVE_PRODUCTS,
	payload: {
		productId,
		amount,
		price
	}
})

const setCreatingCheckoutAction = (value: boolean): SetCreatingCheckoutActionType => ({
	type: ActionTypes.SET_CREATING_CHECKOUT,
	payload: {
		value
	}
})

const setCheckoutAction = (checkout: ApiCheckout | null): SetCheckoutActionType => ({
	type: ActionTypes.SET_CHECKOUT,
	payload: {
		checkout
	}
})

const setCheckoutExpiredAction = (checkoutExpired: boolean): SetCheckoutExpiredActionType => ({
	type: ActionTypes.SET_CHECKOUT_EXPIRED,
	payload: {
		checkoutExpired
	}
})

const setRehydratingAction = (value: boolean): SetRehydratingActionType => ({
	type: ActionTypes.SET_REHYDRATING,
	payload: {
		value
	}
})

const setRehydratingCheckoutAction = (value: boolean): SetRehydratingCheckoutActionType => ({
	type: ActionTypes.SET_REHYDRATING_CHECKOUT,
	payload: {
		value
	}
})

const setActiveEventAction = (
	event: Event | null
): SetActiveEventActionType => ({
	type: ActionTypes.SET_ACTIVE_EVENT,
	payload: {
		event
	}
})

const setShoppingCartAction = (
	shoppingCart: ShoppingCart | null
): SetShoppingCartActionType => ({
	type: ActionTypes.SET_SHOPPING_CART,
	payload: {
		shoppingCart
	}
})

const changeTicketStateAction = (
	ticketId: string,
	state: TicketState,
	amountLeft?: number
): ChangeTicketActionType => ({
	type: ActionTypes.CHANGE_TICKET_STATE,
	payload: {
		ticketId,
		state,
		amountLeft
	}
})

const changeProductStateAction = (productId: string, state: ProductState): ChangeProductStateActionType => ({
	type: ActionTypes.CHANGE_PRODUCT_STATE,
	payload: {
		productId,
		state
	}
})

const clearTicketsAction = (): ClearTicketsActionType => ({ type: ActionTypes.CLEAR_TICKETS })

const setProductsAction = (products: (FixedPriceProduct | RangePriceProduct)[]): SetProductsActionType => ({
	type: ActionTypes.SET_PRODUCTS,
	payload: {
		products
	}
})

export {
	ActionTypes,
	reserveTicketsAction,
	reserveProductsAction,
	setCreatingCheckoutAction,
	setCheckoutAction,
	setCheckoutExpiredAction,
	setRehydratingAction,
	setRehydratingCheckoutAction,
	setActiveEventAction,
	setShoppingCartAction,
	changeTicketStateAction,
	changeProductStateAction,
	clearTicketsAction,
	setProductsAction
}

export type {
	ChangeTicketActionType,
	ChangeProductStateActionType,
	ReserveTicketsActionType,
	ReserveProductsActionType,
	SetCreatingCheckoutActionType,
	SetCheckoutActionType,
	SetCheckoutExpiredActionType,
	SetRehydratingActionType,
	SetActiveEventActionType,
	SetShoppingCartActionType,
	SetRehydratingCheckoutActionType,
	ClearTicketsActionType,
	SetProductsActionType
}
