import { FormatMoney } from 'format-money-js'
import {
	Event,
	ShoppingCart,
	ShoppingCartItem,
	ShoppingCartItemTicket,
	Ticket,
	TicketCategory,
	TicketState,
	ProductState,
	ShoppingCartItemProduct,
	FixedPriceProduct,
	RangePriceProduct
} from './types/application-types'
import { ApiCheckout, ApiCheckoutItem, ApiCheckoutItemProduct, ApiCheckoutItemTicket, ApiProduct, PriceType } from './types/api-types'

const mapTicketCategories = (categories: TicketCategory[]) =>
	categories.map(({ maxAmountPerOrder, name, ref, availabilityIndicator, v }) => ({
		availabilityIndicator,
		maxAmountPerOrder,
		name,
		ref,
		v
	}))

const mapTickets = (tickets: Ticket[]) =>
	tickets.map(
		({
			id,
			price,
			name,
			description,
			active,
			taxRate,
			categoryRef,
			dynamicFees,
			minAmountPerOrder,
			v
		}) => ({
			id,
			name,
			price,
			active,
			taxRate,
			description,
			amount: 0,
			categoryRef,
			dynamicFees,
			minAmountPerOrder,
			state: TicketState.INIT,
			v
		})
	)

const mapSeller = ({ _id, name, customLogo, defaultLanguage, url }: any) => ({
	_id,
	name,
	customLogo,
	defaultLanguage,
	url
})

const mapEvent = (eventInfo: any): Event | null => {
	if (eventInfo == null) return null
	const {
		_id,
		url,
		name,
		image,
		accentColor,
		description,
		timezone,
		start,
		location,
		saleStatus,
		startingPrice,
		seller,
		tickets,
		categories,
		styleOptions,
		min,
		max,
		upsellSettings
	} = eventInfo
	return {
		_id,
		url,
		name,
		start,
		image,
		timezone,
		location,
		saleStatus,
		accentColor,
		description,
		startingPrice,
		hideStartingPrice: styleOptions?.hideStartingPrice ?? true,
		showAvailabilityIndicator: styleOptions?.showAvailabilityIndicator ?? false,
		seller: mapSeller(seller),
		tickets: mapTickets(tickets),
		categories: mapTicketCategories(categories),
		min,
		max,
		upsellSettings
	}
}

const mapProducts = (products: ApiProduct[]): (FixedPriceProduct | RangePriceProduct)[] =>
	products.filter(({ active }: ApiProduct) => active).map(({ _id, name, image, description, variants, isFulfillable, type }: ApiProduct): (FixedPriceProduct | RangePriceProduct) => {
		if (variants[0].priceType === PriceType.fixed) {
			const { price, compareAtPrice, priceType, _id: productVariantId, taxRate, taxable } = variants[0]
			return {
				_id,
				name,
				description,
				image,
				price,
				compareAtPrice,
				priceType,
				productVariantId,
				amount: 0,
				state: ProductState.INIT,
				taxRate,
				taxable,
				isFulfillable,
				type
			}
		} else {
			const { compareAtPrice, priceType, _id: productVariantId, taxRate, taxable, priceRange: { min, max } } = variants[0]
			return {
				_id,
				name,
				description,
				image,
				price: min,
				compareAtPrice,
				priceType,
				min,
				max,
				productVariantId,
				amount: 0,
				state: ProductState.INIT,
				taxRate,
				taxable,
				isFulfillable,
				type
			}
		}
		
	})

const mapShoppingCartItemTickets = (tickets: ApiCheckoutItemTicket[]): ShoppingCartItemTicket[] =>
	tickets.map(({ name, price, amount, ticketTypeId, seatingInfo }) => ({
		name,
		price,
		amount,
		ticketTypeId,
		seatingInfo
	}))

const mapShoppingCartItemProducts = (products: ApiCheckoutItemProduct[]): ShoppingCartItemProduct[] => 
	products.map(({ _id, amount, name, price }) => ({
		_id,
		amount,
		name,
		price
	}))

const mapShoppingCartItems = (items: ApiCheckoutItem[]): ShoppingCartItem[] =>
	items.map(
		({ _id, eventId, innerCharge, outerCharge, regularPrice, tickets, products }) => ({
			_id,
			eventId,
			innerCharge,
			outerCharge,
			regularPrice,
			tickets: mapShoppingCartItemTickets(tickets),
			products: mapShoppingCartItemProducts(products)
		})
	)

const mapShoppingCart = (checkout: ApiCheckout): ShoppingCart => {
	// console.log('api shopping cart data', info)
	const { _id, createdAt, expiresAt, realPrice, secret, status, items } = checkout
	return {
		_id,
		createdAt,
		expiresAt,
		realPrice,
		secret,
		status,
		items: mapShoppingCartItems(items)
	}
}

const formatMoney = (amount: number) => {
	const fm = new FormatMoney({
		decimals: 2,
		symbol: '€ ',
		decimalPoint: ','
	})
	return fm.from(amount)!.toString()
}

const formatMoneyWithoutSymbol = (price: number) => {
	const fm = new FormatMoney({
		decimals: 2,
		decimalPoint: ','
	})
	return fm.from(price)!.toString()
}

const classNames = (...classes: string[]) => {
	return classes.filter(Boolean).join(' ')
}

const getRGBColorVar = (hex: string, type: string) => {
	const color = hex.replace(/#/g, '')
	// rgb values
	const r = parseInt(color.substring(0, 2), 16)
	const g = parseInt(color.substring(2, 4), 16)
	const b = parseInt(color.substring(4, 6), 16)

	return `--color-${type}: ${r}, ${g}, ${b};`
}

const getAccessibleColor = (hex: string) => {
	const color = hex.replace(/#/g, '')
	// rgb values
	const r = parseInt(color.substring(0, 2), 16)
	const g = parseInt(color.substring(2, 4), 16)
	const b = parseInt(color.substring(4, 6), 16)
	const yiq = (r * 299 + g * 587 + b * 114) / 1000
	return yiq >= 128 ? '#000000' : '#FFFFFF'
}

export {
	mapEvent,
	mapProducts,
	mapShoppingCart,
	mapShoppingCartItemProducts,
	formatMoney,
	formatMoneyWithoutSymbol,
	classNames,
	getRGBColorVar,
	getAccessibleColor
}
