import times from 'lodash/times'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ApplicationContextType } from './providers/application-provider'
import { createCheckout, bas, updateCheckout } from './api-handler'
import { Event, ProductState, Ticket, TicketState } from './types/application-types'
import { mapShoppingCart } from './util'
import { ProductType } from './types/api-types'

export const useReserveHook = (event: Event | null, application: ApplicationContextType) => {
	const [searchParams, setSearchParams] = useSearchParams()
	const { activeEvent, checkout, products, shoppingCart, setShoppingCart, changeTicketState, changeProductState, setCreatingCheckout, setCheckout, setCheckoutExpired } = application

	const onBasRequest = async (
		eventId: string,
		ticketId: string,
		amount: number,
		checkoutId: string,
		secret: string
	) => {
		const shopId = searchParams.get('underShop')
		return bas(shopId, checkoutId, secret, eventId, ticketId, amount)
			.then((res) => {
				setCheckout(res.data.checkout)
				const shoppingCart = mapShoppingCart(res.data.checkout)
				setShoppingCart(shoppingCart)
				changeTicketState(ticketId, TicketState.INIT)
			})
			.catch((error) => {
				if (error.response?.data?.error === 'NOT_ENOUGH_SEATS') {
					console.log(error.response.data.amountLeft)
					return changeTicketState(ticketId, TicketState.RESERVING_FAILED, error.response.data.amountLeft)
				}
				if (error.response?.data?.error === 'CHECKOUT_STATUS') {
					// This means the checkout is aborted or completed.
					setCheckoutExpired(true)
				}
				changeTicketState(ticketId, TicketState.RESERVING_FAILED)
			})
	}

	const onCreateCheckout = async (eventId: string, ticketId: string, amount: number) => {
		const shopId = searchParams.get('underShop')
		return createCheckout(shopId, eventId, times(amount, () => ({ amount: 1, ticketTypeId: ticketId })))
			.then(async (res) => {
				// Add checkoutId and secret to the url.
				const newParams = new URLSearchParams(searchParams)
				newParams.set('checkoutId', res.data._id)
				newParams.set('secret', res.data.secret)
				setSearchParams(newParams)

				await onBasRequest(eventId, ticketId, amount, res.data._id, res.data.secret)
				setCreatingCheckout(false)
			})
			.catch((error) => {
				console.log(error.response.data)
				changeTicketState(ticketId, TicketState.RESERVING_FAILED)
			})
	}

	const handleReserve = async (eventId: string, tickets: Ticket[]) => {
		for (const ticket of tickets) {
			if (ticket.state === TicketState.RESERVING) {
				setCreatingCheckout(true)
				await onCreateCheckout(eventId, ticket.id, ticket.amount)
			} else if (ticket.state === TicketState.UPDATING) {
				if (shoppingCart) {
					const { _id, secret } = shoppingCart
					await onBasRequest(eventId, ticket.id, ticket.amount, _id, secret)
				}
			}
		}
	}

	const handleReserveProducts = async () => {
		// We should always have a checkout when calling this.
		const product = products.find((product) => product.state === ProductState.RESERVING || product.state === ProductState.UPDATING)
		if (!product) return
		if (product.state === ProductState.RESERVING || product.state === ProductState.UPDATING) {
			const { _id, secret, items } = checkout!
			const itemForActiveEvent = items.find(({ eventId }: { eventId: string }) => activeEvent!._id === eventId)
			const { shopId, seatingReservationToken, tickets, products: checkoutProducts } = itemForActiveEvent!
			const ticketCartItems = tickets.map(({ _id, amount, ticketTypeId, type, seatingInfo }) => ({
				_id,
				amount,
				ticketTypeId,
				type,
				seatingInfo
			}))
			const productCartItems = products.reduce((acc: any, { amount, name, price, productVariantId, taxRate, isFulfillable }: any) => {
				if (amount === 0) return acc

				const checkoutProduct = checkoutProducts.find((checkoutProduct) => checkoutProduct.productVariantId === productVariantId)
				if (checkoutProduct) {
					return [
						...acc, 
						{
							productVariantId,
							name: checkoutProduct.name,
							amount,
							price,
							taxRate: checkoutProduct.taxRate,
							isFulFillable: checkoutProduct.isFulfillable,
							type: checkoutProduct.type,
							_id: checkoutProduct._id
						}
					]
				} else {
					return [
						...acc, 
						{
							productVariantId,
							name,
							amount,
							price,
							taxRate,
							isFulfillable,
							type: ProductType.product
						}
					]
				}
			}, []) as any

			const updatedItems = []
			if (ticketCartItems.length > 0) updatedItems.push(...ticketCartItems)
			if (productCartItems.length > 0) updatedItems.push(...productCartItems)
			// Map full checkout for adding products
			updateCheckout(_id, secret, activeEvent!._id, updatedItems, shopId, seatingReservationToken ).then((res) => {
				setCheckout(res.data)
				const shoppingCart = mapShoppingCart(res.data)
				setShoppingCart(shoppingCart)
			}).catch((error) => {
				if (error.response?.data?.message === 'Checkout is already in final state') {
					// This means the checkout is aborted or completed.
					setCheckoutExpired(true)
				}
				// Reset amount/price of product and set failed state
				changeProductState(product._id, ProductState.RESERVING_FAILED)
			})
		}
	}

	useEffect(() => {
		handleReserveProducts()
	}, [products])

	useEffect(() => {
		if (event) {
			handleReserve(event._id, event.tickets)
		}
	}, [event, event?.tickets])
}
