import React, { useMemo } from 'react'
import { formatMoney } from '../../util'
import { ShoppingCartItemTicket as ShoppingCartItemTicketType } from '../../types/application-types'

type Props = {
  ticket: ShoppingCartItemTicketType
}

const ShoppingCartItemTicket: React.FC<Props> = ({ ticket }) => {
	/** State Management */
	const ticketPrice = useMemo(() => formatMoney(ticket.price), [ticket])

	return (
		<li className="text-base font-medium text-gray-800 py-4 flex flex-col">
			<div className="flex justify-between mb-0.5">
				<span>{ticket.name}</span>
				<span>{ticketPrice}</span>
			</div>
			{ticket.seatingInfo && (
				<>
					<div className="flex gap-x-3">
						<div className='flex flex-col'>
							<div className='text-sm text-gray-500'>Sectie</div>
							<div className='text-sm text-gray-600'>{ticket.seatingInfo.sectionName}</div>
						</div>
						{ticket.seatingInfo.groupName && (
							<div className='flex flex-col'>
								<div className='text-sm text-gray-500'>Blok</div>
								<div className='text-sm text-gray-600'>{ticket.seatingInfo.groupName}</div>
							</div>)
						}
						<div className='flex flex-col'>
							<div className='text-sm text-gray-500'>Rij</div>
							<div className='text-sm text-gray-600'>{ticket.seatingInfo.rowName}</div>
						</div>
						<div className='flex flex-col'>
							<div className='text-sm text-gray-500'>Stoel</div>
							<div className='text-sm text-gray-600'>{ticket.seatingInfo.seatName}</div>
						</div>
					</div>
				</>
			)}
		</li>
	)
}

export default React.memo<Props>(ShoppingCartItemTicket)
