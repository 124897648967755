import React, { useMemo } from 'react'
import { Oval } from 'react-loader-spinner'
import { FixedPriceProduct as FixedPriceProductType, ProductState } from '../../../types/application-types'
import { classNames, formatMoney } from '../../../util'
import { useApplication } from '../../../providers/application-provider'
import ProductSelect from './product-select'

type Props = {
  disabled: boolean
  product: FixedPriceProductType
  onSelectProducts: (productId: string, amount: number) => void
}

const FixedPriceProduct: React.FC<Props> = ({ disabled, product, onSelectProducts }) => {
  const {
		application: { activeEvent }
	} = useApplication()
  const productPrice = useMemo(() => formatMoney(product.price), [product.price])
  const productCompareAtPrice = useMemo(() => product.compareAtPrice != null ? formatMoney(product.compareAtPrice) : null, [product.compareAtPrice])

  const persisting = useMemo(
		() =>
			product.state === ProductState.RESERVING ||
    product.state === ProductState.UPDATING,
		[product.state]
	)
	const hasError = useMemo(
		() =>
			product.state === ProductState.RESERVING_FAILED ||
    product.state === ProductState.UPDATING_FAILED,
		[product.state]
	)
  const onSelectAmount = (amount: number) => onSelectProducts(product._id, amount)

  return (
    <div
			className={classNames(
				hasError ? 'border-red-500' : '',
				'flex-column border-2 rounded-md py-4 px-6'
			)}
		>
			<div className="flex justify-between gap-4">
        <div className="flex flex-wrap">
          {product.image && (
            <div className='w-24 h-24 overflow-hidden mr-4'>
              <img className='w-24 h-24 object-contain ease-in-out duration-300 hover:scale-125 hover:cursor-pointer' alt={product.name} src={product.image} />
            </div>
          )}
          <div className="flex flex-col gap-1">
            <h4 className="flex text-xl font-medium">{product.name}</h4>
            {product.description && (
              <h5 className="text-md text-gray-500">{product.description}</h5>
            )}
            <div className="flex mt-1 items-center">
              {productCompareAtPrice && (
                <span className="text-sm text-gray-500 line-through mr-4">{productCompareAtPrice}</span>
              )}
              <span className="text-md font-medium">{productPrice}</span>
            </div>
          </div>
        </div>
        
				{persisting ? (
					<div className="flex justify-center self-center min-w-24">
						<Oval
							visible
							height="30"
							width="30"
							color={activeEvent?.accentColor ?? '#000000'}
							secondaryColor={`${activeEvent?.accentColor ?? '#000000'}80`}
							ariaLabel="persisting-product"
						/>
					</div>
				) : (
					<ProductSelect disabled={disabled} productVariantId={product.productVariantId} onSelectAmount={onSelectAmount} />
				)}
			</div>
      {hasError && (
				<div className="text-red-600 pt-2 font-medium">
          Er is iets fout gelopen bij het reserveren van uw producten. Gelieve
          opnieuw te proberen.
				</div>
			)}
		</div>
  )
}

export default React.memo<Props>(FixedPriceProduct)