import times from 'lodash/times'
import groupBy from 'lodash/groupBy'
import { Listbox, Transition } from '@headlessui/react'
import CheckIcon from '@heroicons/react/24/outline/CheckIcon'
import React, { Fragment, useEffect, useMemo, useState } from 'react'
import ChevronUpDownIcon from '@heroicons/react/24/outline/ChevronUpDownIcon'
import { useApplication } from '../../../providers/application-provider'
import { classNames } from '../../../util'

type Select = {
  id: number
  value: number
}

type Props = {
  ticketId: string
  disabled: boolean
  maxAmount: number
  minAmount: number
  availableAmount: number
  onSelectAmount: (amount: number) => void
}

const TicketSelect: React.FC<Props> = ({
	ticketId,
	disabled,
	maxAmount,
	minAmount,
	availableAmount,
	onSelectAmount
}) => {
	const {
		application: { activeEvent, shoppingCart }
	} = useApplication()

	/** State Management */
	const [selected, setSelected] = useState<Select>({ id: 0, value: 0 })
	
	const options = useMemo(
		() => {
			const amount = availableAmount < maxAmount ? availableAmount : maxAmount
			return times(amount + 1, (idx) => ({ id: idx, value: idx }))
		} ,
		[availableAmount, maxAmount]
	)

	/** Methods */
	const onSelect = ({ id, value }: Select) => {
		if (id === selected.id) return 
		onSelectAmount(value)
	}

	/** Render Methods */
	const renderOptions = () => options
		.filter(({ id }) => id >= minAmount)
		.map((option) => (
			<Listbox.Option
				key={option.id}
				className={({ active }) =>
					classNames(
						active ? 'bg-primary text-accessible' : 'text-gray-900',
						'relative cursor-default min-w-24 select-none py-1 pl-8 pr-6'
					)
				}
				value={option}
			>
				{({ selected, active }) => (
					<>
						<span
							className={classNames(
								selected ? 'font-semibold' : 'font-normal',
								'block truncate text-right'
							)}
						>
							{option.value}
						</span>

						{selected ? (
							<span
								className={classNames(
									active ? 'text-accessible' : 'text-primary',
									'absolute inset-y-0 left-0 flex items-center pl-1.5'
								)}
							>
								<CheckIcon className="h-5 w-5" aria-hidden="true" />
							</span>
						) : null}
					</>
				)}
			</Listbox.Option>
		))

	/** Lifecycle Methods */
	useEffect(() => {
		if (shoppingCart) {
			const itemForActiveEvent = shoppingCart.items.find(({ eventId }) => activeEvent?._id === eventId)

			if (itemForActiveEvent) {
				const groupedByTicketTypeId = groupBy(itemForActiveEvent.tickets, 'ticketTypeId')

				// Only count tickets with an actual seat.
				const ticketsForTicketType = (groupedByTicketTypeId[ticketId] ?? []).filter((ticket) => ticket.seatingInfo != null)
				if (ticketsForTicketType != null) {
					const selectedOption = options.find(({ value }) => value === ticketsForTicketType.length)
					if (selectedOption) setSelected(selectedOption)
				}
			}
		} else {
			// Reset dropdown
			if (options.length > 0) {
				setSelected(options[0])
			}
		}
	}, [shoppingCart, options])

	return (
		<Listbox disabled={disabled} value={selected} onChange={onSelect}>
			{({ open, disabled }) => (
				<>
					<div className="relative self-center">
						<Listbox.Button
							className={classNames(
								disabled
									? 'bg-gray-50 cursor-not-allowed text-gray-400'
									: 'bg-white text-gray-900',
								'relative w-full cursor-default min-w-24 rounded-md py-3 pl-5 pr-10 text-left text-lg shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary'
							)}
						>
							<span className="block truncate text-right">
								{selected.value}
							</span>
							<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
								<ChevronUpDownIcon
									className="h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
								{renderOptions()}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	)
}

export default React.memo<Props>(TicketSelect)
