import React from 'react'
import ShoppingCartItemTicket from './shopping-cart-item-ticket'
import ShoppingCartItemProduct from './shopping-cart-item-product'
import { ShoppingCartItem as ShoppingCartItemType } from '../../types/application-types'

type Props = {
  item: ShoppingCartItemType
}

const ShoppingCartItem: React.FC<Props> = ({ item }) => {
	return (
		<>
			{item.tickets.map((ticket, idx) => (
				<ShoppingCartItemTicket
					key={`shoppingCartItemTicket${ticket.ticketTypeId}${idx}`}
					ticket={ticket}
				/>
			))}
			{item.products.map((product) => (
				<ShoppingCartItemProduct key={`shoppingCartItemProduct${product._id}`} product={product} />
			))}
		</>
	)
}

export default React.memo<Props>(ShoppingCartItem)
