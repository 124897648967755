import axios from 'axios'

/** QUERIES */
export const fetchEventInfo = async (eventId?: string) =>
	axios(`${process.env.REACT_APP_API_ENDPOINT}/events/info/${eventId}`)

export const fetchCheckout = async (checkoutId: string, secret: string) =>
	axios(`${process.env.REACT_APP_API_ENDPOINT}/checkout/${checkoutId}?secret=${secret}`)

export const fetchProducts = async (productStreamId: string) => 
	axios(`${process.env.REACT_APP_API_ENDPOINT}/products/streams/${productStreamId}/products`)

/** MUTATIONS */
export const createCheckout = async (shopId: string | null, eventId: string, selectedTickets: { amount: number; ticketTypeId: string }[]) =>
	axios.post(`${process.env.REACT_APP_API_ENDPOINT}/checkout`, {
		eventId,
		type: 'transaction',
		items: selectedTickets,
		...(shopId ? { shopId } : {})
	})

export const bas = async (
	shopId: string | null,
	checkoutId: string,
	secret: string,
	eventId: string,
	ticketTypeId: string,
	amount: number
) =>
	axios.post(`${process.env.REACT_APP_BAS_ENDPOINT}`, {
		shopId,
		checkoutId,
		secret,
		eventId,
		ticketTypeId,
		amount
	})

export const updateCheckout = async (
	checkoutId: string,
	secret: string,
	eventId: string,
	items: any,
	shopId: string,
	seatingReservationToken: string
) => {

	return axios.post(
		`${process.env.REACT_APP_API_ENDPOINT}/checkout/${checkoutId}/items`,
		{
			secret,
			eventId,
			items,
			seatingReservationToken,
			...(shopId ? { shopId } : {})
		}
	)
}
	
