// ENUMS
enum CheckoutStatus {
  NEW = 'NEW',
  ABORTED = 'ABORTED',
  COMPLETE = 'COMPLETE'
}

enum CheckoutType {
  transaction = 'transaction',
  upgrade = 'upgrade',
  rebooking = 'rebooking',
  purchaseintent = 'purchaseintent',
  subscription = 'subscription'
}

enum PriceType {
  fixed = 'fixed',
  range = 'range'
}

enum ProductType {
  voucher = 'voucher',
  product = 'product',
  donation = 'donation'
}

// API TYPES

type ApiVoucher = {
  _id: string
  code: string
  redeemedAmount: number
}

type ApiRedeemedVoucher = {
  totalRedeemAmount: number
  redeemedVouchers: ApiVoucher[]
}

type ApiAppliedCoupon = {
  _id: string
  code: string
}

type ApiCheckoutItemTicket = {
  // Required
  amount: number
  price: number
  ticketTypeId: string
  // Optional
  type: string
  _id: string
  name: string
  netPrice: number
  taxRate: number
  triggeredBy: any
  bundleInfo: any
  categoryRef: string
  seatingInfo: {
    _type: number
    statusId: string
    _id: string
    categoryId: string
    name: string
    seatType: string
    sectionName: string
    groupName: string
    rowName: string
    seatName: string
  }
  asHardTicket: boolean
  triggeredAutomations: boolean
  meta: any
}

type ApiCheckoutItemProduct = {
  // Required
  amount: number
  price: number
  productVariantId: string
  type: ProductType
  // Optional
  _id: string
  name: string
  netPrice: number
  taxRate: number
  triggeredBy: any
  bundleInfo: any
  isFulfillable: boolean
}

// Incomplete
type ApiCheckoutItem = {
  // Required
  _id: string
  eventId: string
  regularPrice: number
  realPrice: number
  outerCharge: number
  // Optional
  shopId: string
  channelId: string
  redeemedVouchers: ApiRedeemedVoucher
  appliedCoupons: ApiAppliedCoupon[]
  appliedDiscountInfo: any
  innerFeeComponents: any
  innerCharge: number
  outerFeeComponents: any
  tickets: ApiCheckoutItemTicket[]
  products: ApiCheckoutItemProduct[]
  additionalItems: any
  seatingReservationToken: string
  subscriptionId: string
}

type ApiAddress = {
  street: string
  line2: string
  postal: string
  city: string
  country: string
  state: string
}

type ApiCheckout = {
  // Required
  _id: string
  secret: string
  status: CheckoutStatus
  type: CheckoutType
  sellerId: string
  items: ApiCheckoutItem[]
  realPrice: number
  // Optional
  company: string
  firstname: string
  name: string
  email: string
  phone: string
  customerId: string
  address: ApiAddress
  deliveryAddress: ApiAddress
  currency: string
  redeemedVouchers: ApiRedeemedVoucher
  preferredLanguage: string
  origin: string
  channel: string
  salesChannelId: string
  posId: string
  userId: string
  extraFields: any
  expiresAt: string
  createdAt: string
  updatedAt: string
}

type ApiPriceRange = {
  min: number
  max: number
}

type ApiVariant = {
  _id: string
  priceType: PriceType
  price: number
  priceRange: ApiPriceRange
  compareAtPrice: number | null
  taxable: boolean
  taxRate: number
  taxServiceTypeId: string
}

type ApiProduct = {
  _id: string
  isFulfillable: boolean
  name: string
  image: string
  description: string
  type: ProductType
  variants: ApiVariant[]
  sellerId: string
  streams: string[]
  voucherSettings: any
  active: boolean
  createdAt: string
  updateAt: string
  __v: number
}

export { ProductType, PriceType }
export type { ApiCheckout, ApiCheckoutItem, ApiCheckoutItemTicket, ApiCheckoutItemProduct, ApiProduct }