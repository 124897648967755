import { useDebouncedCallback } from 'use-debounce'
import React, { useEffect, useMemo, useState } from 'react'
import { Oval } from 'react-loader-spinner'
import { RangePriceProduct as RangePriceProductType, ProductState } from '../../../types/application-types'
import { classNames, formatMoney } from '../../../util'
import { useApplication } from '../../../providers/application-provider'
import ProductSelect from './product-select'

type Props = {
  disabled: boolean
  product: RangePriceProductType
  onSelectProducts: (productId: string, amount: number, price?: number) => void
}

const RangePriceProduct: React.FC<Props> = ({ disabled, product, onSelectProducts }) => {
  const {
		application: { activeEvent }
	} = useApplication()
  const productCompareAtPrice = useMemo(() => product.compareAtPrice != null ? formatMoney(product.compareAtPrice) : null, [product.compareAtPrice])
  const minPrice = useMemo(() => formatMoney(product.min), [product.min])
  const maxPrice = useMemo(() => formatMoney(product.max), [product.max])
  const [price, setPrice] = useState<number>(product.price)
  const [priceError, setPriceError] = useState<boolean>(false)

  const debounce = useDebouncedCallback((newPrice) => {
    console.log('Set new price: ', newPrice)
    setPrice(newPrice)
  }, 500)

  const persisting = useMemo(
		() =>
			product.state === ProductState.RESERVING ||
    product.state === ProductState.UPDATING,
		[product.state]
	)
	const hasError = useMemo(
		() =>
			product.state === ProductState.RESERVING_FAILED ||
    product.state === ProductState.UPDATING_FAILED,
		[product.state]
	)

  const onSelectAmount = (amount: number) => onSelectProducts(product._id, amount, price)

  const onChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    debounce(Number(value))
  }

  useEffect(() => {
    const priceInRange = price >= product.min && price <= product.max
    if (priceInRange) {
      setPriceError(false)
    } else {
      setPriceError(true)
    }
    if (price) {
      if (product.amount !== 0 && priceInRange) {
        onSelectProducts(product._id, product.amount, price)
      }
    }
  }, [price])

  return (
    <div
			className={classNames(
				(hasError || priceError) ? 'border-red-500' : '',
				'flex-column border-2 rounded-md py-4 px-6'
			)}
		>
			<div className="flex justify-between gap-4">
        <div className="flex flex-wrap">
          {product.image && (
            <div className='w-24 h-24 overflow-hidden mr-4'>
              <img className='w-24 h-24 object-contain ease-in-out duration-300 hover:scale-125 hover:cursor-pointer' alt={product.name} src={product.image} />
            </div>
          )}
          <div className="flex flex-col gap-1">
            <h4 className="flex text-xl font-medium">{product.name}</h4>
            {product.description && (
              <h5 className="text-md text-gray-500">{product.description}</h5>
            )}
            <div className="flex mt-1 items-center">
              {productCompareAtPrice && (
                <span className="text-sm text-gray-500 line-through mr-4">{productCompareAtPrice}</span>
              )}
            </div>
            <div>
              <div className="relative mt-2 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">€</span>
                </div>
                <input
                  type="number"
                  name="price"
                  id="price"
                  className="block w-full rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6"
                  placeholder="0,00"
                  aria-describedby="price-currency"
                  min={product.min}
                  max={product.max}
                  defaultValue={price}
                  onChange={onChangePrice}
                />
              </div>
              {priceError 
                ? (
                  <p className="mt-2 text-sm text-red-600" id="price-description">
                    {`Kies een prijs tussen ${minPrice} en ${maxPrice}.`}
                  </p>
                ) : ( 
                  <p className="mt-2 text-sm text-gray-500" id="price-description">
                    {`Kies een prijs tussen ${minPrice} en ${maxPrice}.`}
                  </p>
                )}
            </div>
          </div>
        </div>
        
				{persisting ? (
					<div className="flex justify-center self-center min-w-24">
						<Oval
							visible
							height="30"
							width="30"
							color={activeEvent?.accentColor ?? '#000000'}
							secondaryColor={`${activeEvent?.accentColor ?? '#000000'}80`}
							ariaLabel="persisting-product"
						/>
					</div>
				) : (
					<ProductSelect disabled={priceError || disabled} productVariantId={product.productVariantId} onSelectAmount={onSelectAmount} />
				)}
			</div>
      {hasError && (
				<div className="text-red-600 pt-2 font-medium">
          Er is iets fout gelopen bij het reserveren van uw producten. Gelieve
          opnieuw te proberen.
				</div>
			)}
		</div>
  )
}

export default React.memo<Props>(RangePriceProduct)